import type { User } from '~/types/user'

function isUUIDv4(uuid: string) {
  const pattern = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/
  return pattern.test(uuid)
}

export function getCarerIndex(id: string): number | undefined {
  if (!isUUIDv4(id)) {
    throw new Error('Invalid UUID')
  }
  const clinicStore = useClinicStore()
  return clinicStore.staff?.findIndex((x) => x.id === id)
}

export function getCarer(id: string): User | undefined {
  const clinicStore = useClinicStore()
  const carerIndex = getCarerIndex(id)

  // If carer is not found
  if (carerIndex === undefined) return undefined

  return clinicStore.staff[carerIndex]
}

export function getCarerName(id: string) {
  const clinicStore = useClinicStore()
  const carerIndex = getCarerIndex(id)

  // If carer is not found
  if (!carerIndex || !clinicStore.staff[carerIndex])
    return 'Utilizador não encontrado'

  return `${clinicStore.staff[carerIndex].first_name} ${clinicStore.staff[carerIndex].last_name}`
}

export function getCarerProfilePicture(id: string): User['profile_picture'] {
  const clinicStore = useClinicStore()
  const carerIndex = getCarerIndex(id)

  // If carer is not found
  if (!carerIndex || !clinicStore.staff[carerIndex])
    return {
      default: null,
      small: null,
    }

  return clinicStore.staff[carerIndex].profile_picture
}

export function getCarerColor(id: string) {
  const clinicStore = useClinicStore()
  const carerIndex = getCarerIndex(id)

  // If carer is not found
  if (!carerIndex || !clinicStore.staff[carerIndex]) return '#0ea5e9' // Our default blue

  return clinicStore.staff[carerIndex].color
}

export function calculateCarerColor(carerColor: string) {
  if (!carerColor) return '#fff'

  return Number.parseInt(carerColor.replace('#', ''), 16) > 0xffffff / 2
    ? '#000'
    : '#fff'
}
